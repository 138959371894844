import { Box, Link } from '@stars-ecom/shared-atoms-ui'
import { WebsiteContext } from '@stars-ecom/shared-organisms-ui'
import React, { useContext } from 'react'

import Phone from './Phone'

const SavPhone = (props) => {
  const { desktopHide } = props
  const websiteContext = useContext(WebsiteContext)

  return (
    <Box
      className="savPhone"
      display={!desktopHide ? 'block' : ['block', 'block', 'none', 'none']}>
      <Link
        to={`tel:${websiteContext?.savPhoneNumber?.replaceAll(' ', '')}`}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Phone phoneNumber={websiteContext?.savPhoneNumber} color="#91919b" />
      </Link>
    </Box>
  )
}

export default SavPhone

import { Box, Flex, GatsbyImage, Text } from '@stars-ecom/shared-atoms-ui'
import { WebsiteContext } from '@stars-ecom/shared-organisms-ui'
import OrderPhone from '@stars-ecom/shared-organisms-ui/src/phone/OrderPhone'
import SavPhone from '@stars-ecom/shared-organisms-ui/src/phone/SavPhone'
import React, { useContext } from 'react'

const DISPLAY_MODE = {
  SHORT_MAINTENANCE: 'SHORT',
  LONG_MAINTENANCE: 'LONG'
}

const MaintenancePage = (props) => {
  const { displayMode = DISPLAY_MODE.LONG_MAINTENANCE } = props
  const websiteContext = useContext(WebsiteContext)

  return (
    <Flex maxWidth="1000px" flexDirection="column" margin="32px auto">
      <Flex justify="center">
        <GatsbyImage
          image={websiteContext?.logo?.image?.childImageSharp?.gatsbyImageData}
          alt="logo"
          loading="eager"
          style={{ maxWidth: '128px', height: 'auto' }}
        />
      </Flex>
      <Box m={{ base: '28px 16px 0', md: '28px 0 0' }}>
        <Text textAlign="center" fontSize="20px">
          Pour des raisons techniques, le site
          <Text as="span" fontWeight="600" p="0 6px">
            {websiteContext?.title}
          </Text>
          est temporairement inaccessible.
        </Text>
        <Text textAlign="center" fontSize="20px">
          Il sera à nouveau disponible dans quelques heures.
        </Text>
        <Text textAlign="center" fontSize="20px">
          Merci pour votre compréhension.
        </Text>
        <Flex mt="28px" flexDirection={{ base: 'column', md: 'row' }} alignItems="center">
          {displayMode === DISPLAY_MODE.SHORT_MAINTENANCE && (
            <Text mr="8px" mb={{ base: '16px', md: '0' }} fontWeight="600" fontSize="18px">
              Pour passer commande, veuillez composer le
            </Text>
          )}
          {displayMode === DISPLAY_MODE.LONG_MAINTENANCE && (
            <Text mr="8px" mb={{ base: '16px', md: '0' }} fontWeight="600" fontSize="18px">
              Pour passer commande, vous pouvez nous joindre au
            </Text>
          )}
          <OrderPhone labelHide={true} />
        </Flex>
        {displayMode === DISPLAY_MODE.LONG_MAINTENANCE && (
          <Flex flexDirection={{ base: 'column', md: 'row' }} alignItems="center" m="32px 0">
            <Text mr="8px" mb={{ base: '16px', md: '0' }} fontWeight="600" fontSize="18px">
              Pour une information sur une commande en cours, vous pouvez nous joindre au
            </Text>
            <SavPhone />
          </Flex>
        )}
      </Box>
    </Flex>
  )
}

MaintenancePage.pageType = 'maintenance'

export default MaintenancePage
